<template>
  <responsive-form :form="form">
    <a-form-item label="活动ID">
      <a-input v-decorator="['id', { initialValue: 0 }]" :disabled="this.model != null" />
    </a-form-item>
    <a-form-item label="类型">
      <a-input v-decorator="['type', {rules: [{required: true}], initialValue: '0'}]" />
    </a-form-item>
    <a-form-item label="开始时间">
      <a-input v-decorator="['startDate', {rules: [{required: true}], initialValue: '2005-01-01 00:00:00.0'}]" />
    </a-form-item>
    <a-form-item label="结束时间">
      <a-input v-decorator="['endDate', {rules: [{required: true}], initialValue: '2029-01-01 23:59:59.0'}]" />
    </a-form-item>
    <a-form-item v-if="!isTemplate" label="备注">
      <a-input v-decorator="['remarks', {rules: [{required: false}]}]" />
    </a-form-item>
  </responsive-form>
</template>

<script>
import pick from 'lodash.pick'
import ResponsiveForm from '@/components/ResponsiveForm/ResponsiveForm'

// 表单字段
const fields = ['id', 'type', 'startDate', 'endDate', 'remarks']

export default {
  components: { ResponsiveForm },
  props: {
    model: {
      type: Object,
      default: () => null
    },
    isTemplate: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      form: this.$form.createForm(this)
    }
  },
  created () {
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))

    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
  }
}
</script>
